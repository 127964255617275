import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortDate',
  standalone: true,
})
export class ShortDatePipe extends DatePipe implements PipeTransform {
  public static readonly DateFormat = 'dd.MM.yyyy';

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  override transform(value: any): any {
    return super.transform(value, ShortDatePipe.DateFormat);
  }
}
